<template>
  <div>
    <div style="">
      <div style="margin: 8px">

      </div>

      <el-row style="margin: 8px">
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">
            <el-select v-model="machine" placeholder="请选择机组" style="width: 100%">
              <el-option
                v-for="item in machines"
                :key="item"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
        </el-col>
      </el-row>

      <el-row style="margin: 8px">
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">
            <el-input placeholder="请输入" v-model="water">
              <template slot="prepend">水表</template>
            </el-input>
          </div>
        </el-col>
      </el-row>


      <el-row style="margin: 8px">
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">
            <el-input placeholder="请输入" v-model="power">
              <template slot="prepend">电表</template>
            </el-input>
          </div>
        </el-col>
      </el-row>

      <el-row style="margin: 8px">
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">
            <el-input placeholder="请输入" v-model="heat">
              <template slot="prepend">热表</template>
            </el-input>
          </div>
        </el-col>
      </el-row>

      <div style="margin-top: 20px">
        <el-button type="primary" @click="saveData" >提交</el-button>
      </div>

    </div>

  </div>
</template>

<script>
import clientUtils from "@/js/clientUtils";
import api from "@/js/api";

export default {
  name: "Input",
  data() {
    return {
      machine: "",
      machines: [],
      water: "",
      power: "",
      heat: ""
    }
  },
  mounted() {
    this.loadAllMachine()
  },
  methods: {
    loadAllMachine: function () {
      const that = this;
      clientUtils.get(api.GET_ALL_MACHINE, function (res) {
        if (res.data) {
          that.machines = res.data;
        }
      });
    },
    saveData() {
      const that = this;
      clientUtils.get("/system/meter/updateByMachineId?machineId=" + this.machine + "&water=" + this.water + "&power=" + this.power + "&heat=" + this.heat, function (res) {
        if (res.data) {
          that.$message({
            message: '抄表成功',
            type: 'success'
          });
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
